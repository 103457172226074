<template>

  <div>

    <list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      @refetch-data="refetchData"
      @emitAddSuccess="emitAddSuccess"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
                v-if="$can('create', 'asset')"
              >
                <span class="text-nowrap">Tambah Kategori Keterampilan</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchDatas"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(nama)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.nama)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"

              />
            </template>
              {{ data.item.nama }}
            <small class="text-muted">{{ data.item.alamat }}</small>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <!-- <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template> -->
            <!-- <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="primary" :to="{ name: 'asset-viewx', params: { id: data.item.id } }"
              title="Lihat Data"
              alt="Lihat Data"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-button> //-->

            <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="success" :to="{ name: 'kategori-keterampilan-edit', params: { id: data.item.id } }" title="Ubah Data"
              v-if="$can('update', 'kategori-keterampilan')"
            >
              <feather-icon icon="EditIcon" />
              <!-- <span class="align-middle ml-50">Edit</span> //-->
            </b-button>

            <b-button
              class="btn-icon btn btn-relief-primary"
              variant="warning"
              @click="deleteData(data.item.id)" title="Hapus Data" alt="Hapus Data"
            >
              <feather-icon icon="TrashIcon" />
              <!-- <span class="align-middle ml-50">Delete</span> //-->
            </b-button>
          <!-- </b-dropdown> -->
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Menampilkan {{ dataMeta.from }} per {{ dataMeta.to }} dari {{ dataMeta.of }} data </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  deleteKategoriKeterampilan,
} from '@/api/user'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/connection-api/kategori-keterampilan/storeModule'
import useList from '@/connection-api/kategori-keterampilan/list'
import listAddNew from './ListAddNew.vue'
import listFilters from './ListFilters.vue'

export default {
  components: {
    // listFilters,
    listAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    // BLink,
    // BDropdown,
    // BDropdownItem,
    BPagination,

    vSelect,
  },
  async mounted() {
    // const responseJenisUptOptions = await getJenisUPT()
    // this.mappingSelectOption(responseJenisUptOptions.data, this.jenisUptOptions.options)

    // const responseKanwilOptions = await getKanwil()
    // this.mappingSelectOption(responseKanwilOptions.data, this.kanwilOptions.options)
  },
  methods: {
    emitAddSuccess(assetId) {
      this.$router.replace(`/kategori-keterampilan/edit/${assetId}`)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kategori Keterampilan',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Data berhasil di tambahkan',
            },
          })
        })
    },
    async deleteData(param) {
      if (window.confirm('Apakah anda yakin?')) {
        const dataPost = { id: param }
        const response = await deleteKategoriKeterampilan(dataPost)
        if (response.data.message === 'error') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: 'Tidak dapat menghapus data!',
            },
          })
        } else if (response.data.message === 'success') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Sukses',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Data berhasil dihapus',
            },
          })
          this.$router.go()
        }
      }
    },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'kategori-keterampilan'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      jenisUptFilter,
      kanwilFilter,
      uptFilter,
      jenisAssetFilter,
      statusAssetFilter,
    } = useList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      jenisUptFilter,
      kanwilFilter,
      uptFilter,
      jenisAssetFilter,
      statusAssetFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
